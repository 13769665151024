<template>
  <layout-modal
    :classes="classes"
    v-bind="attrs"
    v-on="listeners">

    <!-- Header -->
    <header class="modal-filters__header">
      <div class="modal-filters__headings">
        <h2 class="modal-filters__title">{{ $t('resto.filters_title')}}</h2>
      </div>
    </header>

    <div class="modal-filters__content">
      <!-- Allergens -->
      <div class="modal-filters__item">

        <!-- Header -->
        <header class="modal-filters__item-header">
          <h2 class="modal-filters__item-title">{{ $t('resto.filters_allergens') }}</h2>
          <div class="modal-filters__item-description">{{ $t('resto.filters_allergens_description') }}</div>
        </header>

        <!-- Content -->
        <div class="modal-filters__item-content">
          <forms-checkbox
            :checked="isAllergenChecked(a.id)"
            :key="a.id"
            @change="onAllergenChange(a.id)"
            v-for="a in allergensList"
          >{{ getAllergenName(a) }}</forms-checkbox>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="modal-filters__footer">
      <actions-button
        :size="$pepper.Size.S"
        @click="onFiltersReset"
      >{{ $t('resto.filters_clear_all') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        :disabled="count === 0"
        :size="$pepper.Size.S"
        @click="onFilter"
      >{{ viewLabel }}</actions-button>
    </div>
  </layout-modal>
</template>

<script>
import MixinAllergens from '../../mixins/allergens'
import LayoutModal from '@/components/layouts/modal'

export default {
  name: 'ModalFilters',

  components: {
    LayoutModal
  },

  inject: ['$catalog'],

  mixins: [
    MixinAllergens
  ],

  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      count: 0,
    }
  },

  computed: {
    allergensList() {
      let allergens = this.$basil.get(this.$catalog, '_product.allergens', [])

      return allergens.map(allergen => {
        let a = this.allergens.find(all => all.id === allergen)
        return a
      })
    },

    attrs() {
      return {
        closable: false,
        visible: this.visible,
      }
    },

    classes() {
      return {
        'modal-filters': true
      }
    },

    filters() {
      return this.$basil.get(this.$catalog, '_product.filters', {})
    },

    listeners() {
      return {
        back: this.back,
      }
    },

    viewLabel() {
      return this.count === 0 ?
        this.$t('resto.filters_no_products_count') :
        this.$t('resto.filters_view_products_count', {count: this.count})
    }
  },

  watch: {
    visible: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal === true && oldVal === false) {
          this.reset()
        }
      },
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    isAllergenChecked(id) {
      return this.filters.excludedAllergens.indexOf(id) >= 0
    },

    onAllergenChange(id) {
      if(this.isAllergenChecked(id)) {
        this.filters.excludedAllergens.splice(this.filters.excludedAllergens.indexOf(id), 1)
      } else {
        this.filters.excludedAllergens.push(id)
      }
      this.$catalog._product.filter()
        .then((p) => this.count = p.length)
        .catch((e) => $console.error(e))
    },

    onFilter() {
      this.$catalog.filter()
        .then(() => this.back())
        .catch((e) => $console.error(e))
    },

    onFiltersReset() {
      this.$catalog.filtersReset()
        .then(() => this.back())
        .catch((e) => $console.error(e))
    },

    reset() {
      this.$catalog._product.filter()
        .then((p) => this.count = p.length)
        .catch((e) => $console.error(e))
    }
  },
}
</script>
