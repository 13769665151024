<template>
  <nav
    v-if="nCategories > 0"
    :class="classes">

    <!-- Ghost -->
    <div class="nav-categories__ghost">
      <!-- Back -->
      <actions-button
        v-if="back"
        appearance="subtle"
        class="nav-categories__back"
        icon-pre="arrow-left"
      >{{ back }}</actions-button>

      <!-- Categories -->
      <ul
        ref="categories"
        class="nav-categories__categories">
        <li
          v-for="c in categories"
          :key="c.id"
          class="nav-categories__category"
          :class="{ '-is-active': isCurrentCategory(c) }">
          <button class="nav-categories__item">{{ c.name }}</button>
        </li>
      </ul>

      <!-- More -->
      <div class="nav-categories__more">
        <actions-button
          class="nav-categories__more-cta"
          appearance="subtle"
          icon-post="angle-down">+99</actions-button>
      </div>
    </div>

    <!-- Inner -->
    <div class="nav-categories__inner">
      <!-- Back -->
      <actions-button
        v-if="back"
        appearance="subtle"
        class="nav-categories__back"
        icon-pre="arrow-left"
        @click="$emit('back')">{{ back }}</actions-button>

      <!-- Categories -->
      <ul class="nav-categories__categories">
        <li
          v-for="c in primary"
          :key="c.id"
          class="nav-categories__category"
          :class="{ '-is-active': isCurrentCategory(c) }">
          <button
            class="nav-categories__item"
            @click="go(c)">{{ c.name }}</button>
        </li>
      </ul>

      <!-- more -->
      <div
        v-if="nSecondary > 0"
        class="nav-categories__more"
        :class="{ '-is-active': showSecondary }">
        <actions-button
          class="nav-categories__more-cta"
          :class="{'-is-active': isSecondaryActive || isSecondarySelected }"
          appearance="subtle"
          icon-post="angle-down"
          @click="showSecondary = !showSecondary">{{ `+ ${nSecondary}` }}</actions-button>

        <ul class="nav-categories__list">
          <li
            v-for="c in secondary"
            :key="c.id"
            class="nav-categories__category"
            :class="{ '-is-active': isCurrentCategory(c) }">
            <button
              class="nav-categories__item"
              @click="go(c)">{{ c.name }}</button>
          </li>
        </ul>
      </div>
    </div>

    <!-- Mobile -->
    <div
      ref="scroll"
      id="nav-categories__scroll"
      class="nav-categories__scroll">
      <!-- Back -->
      <actions-button
        v-if="back"
        appearance="subtle"
        class="nav-categories__back"
        icon-pre="arrow-left"
        @click="$emit('back')"
      />
        <!-- {{ back }}</actions-button> -->

      <!-- Categories -->
      <ul
        class="nav-categories__categories">
        <li
          v-for="c in categories"
          :key="c.id"
          class="nav-categories__category"
          :class="{ '-is-active': isCurrentCategory(c) }">
          <button
            :id="`nav-categories__item__${c.id}`"
            class="nav-categories__item"
            @click="go(c)"
          >{{ c.name }}</button>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NavCategories',

  props: {
    back: {
      type: String
    },

    category: {
      type: Object
    }
  },

  data(){
    return {
      clicked: false,
      children: [],
      observer: null,
      indexes: [],
      nVisible: 0,
      resizeListener: null,
      showSecondary: false,
    }
  },

  computed: {
    ...mapState({
      categoriesS: state => state['sayl-front.catalog'].categories,
      products: state => state['sayl-front.catalog-product'].products
    }),

    classes(){
      return {
        'nav-categories': true,
      }
    },

    isSecondaryActive(){
      return this.secondary.filter(i => this.category && this.categories.id === i.id).length > 0
    },

    isSecondarySelected() {
      return this.secondary.find(s => this.category && this.category.id === s.id)
    },

    categories() {
      return this.categoriesS || []
    },

    nCategories(){
      return  this.categories.length
    },

    nSecondary(){
      return this.secondary.length
    },

    primary(){
      let n = Math.min(this.nVisible, this.nCategories)
      let ret = [...this.categories]
      ret.length = n
      return ret
    },

    secondary(){
      let n = Math.min(this.nVisible, this.nCategories)
      let ret = [...this.categories];
      ret.reverse();
      ret.length = ret.length - n;
      ret.reverse();

      return ret;
    }
  },

  watch: {
    'category.id': {
      immediate: true,
      handler(n, o){
        this.$nextTick(() => {
          let scroll = this.$basil.get(this, '$refs.scroll', null)

          if(!this.$basil.isNil(scroll)) {
            let el = scroll.getElementsByClassName('-is-active')

            if (el && el.length > 0){
              el[0].scrollTo({ behavior: "smooth" })
            }
          }

          if(n !== o && !this.clicked) {
            this.onScroll()
          }
        })
      }
    },

    categories: {
      deep: true,
      handler(n, o){
        this.$nextTick(this.reset)
      }
    }
  },

  methods: {
    debounce(func, wait, immediate){
      var timeout;
      return () => {
        var context = this;
        var args = arguments;
        var later = () => {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
          func.apply(context, args);
        }
      };
    },

    go(category){
      this.clicked = true
      this.$emit('category', category)
      this.showSecondary = false

      clearTimeout(this.deb)
      this.deb = setTimeout(() => {
        this.clicked = false
      }, 1000)
    },

    isCurrentCategory(c){
      return !!this.category && this.category.id === c.id;
    },

    reset(){
      if (!this.$refs.categories){
        return;
      }

      this.indexes = Array(this.nCategories).fill(false);

      if (this.observer){
        this.observer.disconnect()
        delete this.observer
      }
      this.observer = new IntersectionObserver(this.update, {
        delay: 100,
        root: this.$refs.categories,
        trackVisibility: true
      });

      this.children = Array.from(this.$refs.categories.children || []);
      this.children.forEach( c => this.observer.observe(c) );
    },

    update(entries){
      entries.forEach( e => {
        let i = -1;
        this.children.forEach((c, j) => {
          if (c === e.target){
            i = j
          }
        })

        if (i > -1){
          this.indexes[i] = e.intersectionRatio >= .98
        }
      });

      this.nVisible = this.indexes.filter( i => i === true ).length
    },

    onResize(){
      this.reset();
    },

    onScroll() {
      if(this.category) {
        let cts = document.getElementById('nav-categories__scroll')
        let ct = document.getElementById('nav-categories__item__' + this.category.id)

        if(ct) {
          let ctOffsetLeft = ct.offsetLeft
          let ctActualPosition = ct.getBoundingClientRect().x

          let to = null

          if(ctActualPosition < 0) {
            to = ctOffsetLeft
          }

          if(ctActualPosition > (cts.offsetWidth - ct.offsetWidth)) {
            to = ctOffsetLeft - (cts.offsetWidth - ct.offsetWidth)
          }

          if(!this.$basil.isNil(to)) {
            cts.scrollTo({ left: to, behavior: 'smooth' })
          }
        }
      }
    }
  },

  mounted(){
    this.resizeListener = this.onResize.bind(this)
    window.addEventListener('resize', this.debounce(this.resizeListener, 500))

    this.reset();
  },

  beforeDestroy(){
    this.resizeListener = null
    window.removeEventListener('resize', this.resizeListener)
  }
}
</script>
