<template>
  <button
    :class="classes"
    @click="onItemClick">

    <figure
      class="product-suggestion__figure"
      :class="{ '-no-figure': product.figures.length === 0 }">
      <ui-img
        class="product-suggestion__illustration"
        :src="product.figures[0].url"
        :alt="product.name"
        v-if="product.figures.length"
      />

      <button
        class="product-suggestion__hover"
        @click.stop="onFigureClick">
        <ui-icon :glyph="icon"/>
      </button>
    </figure>

    <div class="product-suggestion__body">
      <div class="product-suggestion__row">
        <div class="product-suggestion__title">{{ product.name }}</div>
        <div class="product-suggestion__price">
          <div class="product-suggestion__amount">{{ formatProductPrice(product.price) }}</div>
        </div>
      </div>

      <actions-button
        appearance="subtle"
        class="product-suggestion__cta"
        icon-pre="plus"
        size="s"
        @click="onFigureClick">Add now</actions-button>
    </div>
  </button>
</template>

<script>
import { mapActions } from 'vuex'

import MixinCurrency from '@/helpers/currency'
import MixinError from '../../mixins/error'
import { CartItem } from '@sayl/front-core'
import UiImg from '@/components/ui/img'
import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CatalogProductSuggestion',

  components: { UiImg },

  mixins: [
    MixinCurrency,
    MixinError,
    MixinMultishop
  ],

  inject: ['$cart'],

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      debounce: null,
      enabled: true,
      item: null
    }
  },

  computed: {
    classes(){
      return {
        'product-suggestion': true
      }
    },

    hasOptions(){
      return this.product && this.product.options.length > 0
    },

    icon(){
      return this.hasOptions ? 'search' : 'plus'
    },

    to(){
      return { name: 'sayl-front-catalog.product', params: {
          embed: this.$route.params.embed,
          product: this.product.id,
          service: this.$route.params.service
        }
      }
    }
  },

  methods:{
    ...mapActions({
      change: 'sayl-front.cart/change'
    }),

    formatProductPrice(price){
      let ret = this.toCurrency(price);

      if (price.min && price.max){
        ret = this.toRangeCurrency({min: price, max: price.max})
      }

      return ret;
    },

    add() {
      if(this.$basil.isNil(this.item)) {
        this.item = CartItem.create({
          product: this.product,
          options: [],
          quantity: 1,
          isNew: true,
          shopId: this.$basil.get(this.subshop, 'id', null)
        });
      } else {
        this.item.quantity++;
      }

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.enabled = false;
        this.$cart.add({ item: this.item, refresh: this.isMultiShop === true })
          .then(() => {
            this.enabled = true;
            this.item = null;
          })
          .catch((error) => this.handleError(error));
      }, 250);
    },

    view(){
      this.$router.push( this.to ).catch(() => {});
    },

    onFigureClick(){
      (this.hasOptions ? this.view : this.add)()
    },

    onItemClick(){
      this.view();
    }
  }
}
</script>
