<template>
  <div :class="classes">
    <div
      class="view-catalog-cart__body">
      <!-- Badge -->
      <catalog-cart-badge class="view-catalog-cart__qty" />

      <!-- Cart -->
      <cart
        class="view-catalog-cart__container"
        editable
        :shopable="isMultiShop"
      />

      <!-- Is orderable? -->
      <div 
        class="view-catalog-cart__danger"
        v-if="hasItemsFromClosedShop">
        <notice-banner
          :intent="$pepper.Intent.DANGER"
          :title="$t('resto.cart_some_shops_closed_title')"
        />
      </div>

      <!-- CTA -->
      <actions-button
        appearance="primary"
        class="view-catalog-cart__cta"
        :disabled="!isEnabled"
        :href="{name: 'sayl-front-checkout.review', params: { embed: $route.params.embed }}"
        icon-post="arrow-right"
        v-if="hasItems"
      >{{ $t('resto.cart_primary_action') }}</actions-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Cart from '@/modules/catalog/components/cart/cart'
import CatalogCartBadge from '@/modules/catalog/components/cart/badge'

import MixinMultishop from '@/helpers/multishop'
import MixinOrderable from '@/helpers/orderable'

export default {
  name: 'CatalogCartContainer',

  components: {
    Cart,
    CatalogCartBadge,
  },

  inject: ['$embed'],

  mixins: [ 
    MixinMultishop,
    MixinOrderable,
  ],

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].controller.cart,
    }),

    ...mapGetters({
      shopOpened: 'sayl-front/shopOpened'
    }),

    classes() {
      return {
        'view-catalog-cart': true,
        '-is-empty': !this.hasItems
      }
    },

    // shop() {
    //   return this.$basil.get(this.$embed, 'shop.model')
    // },
  },
}
</script>
