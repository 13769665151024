<template>
  <nav :class="classes">
    <div class="catalog-toolbar__inner">
      <router-link
        :to="{name: 'sayl-front-checkout.cart'}"
        class="catalog-toolbar__cart">
        <catalog-cart-badge class="catalog-toolbar__badge"/>
        <span class="catalog-toolbar__label">{{$t('resto.toolbar_title')}}</span>
      </router-link>

      <actions-button
        :disabled="!isEnabled"
        appearance="primary"
        class="catalog-toolbar__cta"
        icon-post="arrow-right"
        :href="{ name: 'sayl-front-checkout.review', params: { embed: $route.params.embed}}"
      >{{ toCurrency(price, 'currency') }}</actions-button>
    </div>

    <div
      v-if="hasRecommendations"
      :key="key"
      class="catalog-toolbar__suggestions">
      <product-suggestion
        v-for="suggestion in values"
        :key="suggestion.name"
        :product="suggestion"
      />
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import CatalogCartBadge from '@/modules/catalog/components/cart/badge'
import ProductSuggestion from '@/modules/catalog/components/product/suggestion'

import MixinCurrency from '@/helpers/currency'
import MixinMultishop from '@/helpers/multishop'
import MixinOrderable from '@/helpers/orderable'

export default {
  name: 'CatalogToolbar',

  components: {
    CatalogCartBadge,
    ProductSuggestion
  },

  inject: ['$embed'],

  mixins: [
    MixinCurrency,
    MixinMultishop,
    MixinOrderable,
  ],

  data() {
    return {
      suggestionTimeout: 15,
      debounce: null,
      key: 0,
      values: []
    }
  },

  computed:{
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
      recommendations: state => state['ifm.recommendation'].controller.collection,
    }),

    classes() {
      return {
        'catalog-toolbar': true,
        '-is-active': this.nCartItem > 0
      }
    },

    nCartItem() {
      return this.cart ? this.cart.items.length : 0;
    },

    price() {
      return this.cart ? this.cart.total.value : 0;
    },

    hasRecommendations() {
      return this.oat.is_recommendations_enabled && this.values.length > 0
    },
  },

  watch: {
    'recommendations.length': {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal > 0) {
          this.suggestions();
        }
      }
    }
  },

  methods: {
    suggestions() {
      let ret = this.recommendations.length ? this.recommendations.splice(0, 2) : [];;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.values = [];
        this.key++;
      }, this.suggestionTimeout * 1000)

      this.values = ret;
    }
  }
}
</script>
