<template>
  <div
    ref="scroll"
    class="t-hierarchy">
    <!-- Announce -->
    <notice-banner 
      v-if="hasAnnounce"
      v-scroll-reveal>
      <template>
        <ui-wysiwyg :value="announceText"/>
      </template>
    </notice-banner>

    <!-- Categories -->
    <hierarchy-categories
      v-if="!hasCategory"
      @category="onSetActiveCategory"
    />

    <div
      class="t-hierarchy__categories"
      v-if="!hasCategories">
      <data-empty :title="$t('resto.catalog_no_products')" />
    </div>

    <!-- Category Products -->
    <hierarchy-products
      v-if="hasCategory"
      @back="$emit('back')"
      @category="onSetActiveCategory"
    />

    <!-- Toolbar -->
    <catalog-toolbar v-if="!isCatalogReadonly"/>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapMutations
} from 'vuex'

import CatalogToolbar from '@/modules/catalog/components/catalog/toolbar'
import HierarchyCategories from './categories'
import HierarchyProducts from './products'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CatalogTemplateHierarchy',

  components: {
    CatalogToolbar,
    HierarchyCategories,
    HierarchyProducts,
  },

  inject: [
    '$challenge',
    '$embed',
  ],

  mixins: [
    MixinMultishop
  ],

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].controller.cart,
      categories: state => state['sayl-front.catalog'].categories,
      category: state => state['sayl-front.catalog'].category,
      service: state => state['sayl-front.service'].service,
    }),

    ...mapGetters({
      shopOpened: 'sayl-front/shopOpened'
    }),

    announceText() {
      return this.$basil.get(this.$embed, 'embed.model.announceText', null)
    },

    hasAnnounce() {
      return this.$basil.get(this.$embed, 'embed.model.announce', false) === true && this.$basil.get(this.$embed, 'embed.model.announceText', '') !== '' 
    },

    hasCategory() {
      return this.category != null;
    },

    hasCategories() {
      return this.categories && this.categories.length > 0
    },

    hasChallenges() {
      let ret = this.$basil.get(this.oat, 'is_challenge_enabled', false)
      if(ret) {
        ret = this.$basil.get(this.$challenge, 'challenges.length', 0) > 0
      }
      return ret
    },

    hasFlPos() {
      let service = this.$basil.get(this.service, 'name');

      service = service === 'base' ? 'pickup': service

      let ret = this.$basil.get(this.shop, 'flposes', []);
      ret = ret.filter(s => s.services.includes(service))

      return ret.length > 0
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isPickup() {
      return this.service.name === 'pickup'
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    workloadClosed() {
      let ret = this.$basil.get(this.shop, 'flposes', []);
      ret = ret.filter(s => s.workload !== 'closed')

      return ret.length === 0
    }
  },

  watch: {
    category: {
      handler(n, o){
        this.onCategory(n)
      }
    }
  },

  methods: {
    ...mapMutations({
      setCategory: 'sayl-front.catalog/setCategory'
    }),

    onSetActiveCategory(category){
      this.setCategory(category);
    },

    onCategory(category){
      let d = this.$refs.scroll.getBoundingClientRect().top;
      let scroll = window.pageYOffset || document.documentElement.scrollTop;
      let offset = 16 * 16;

      setTimeout(() =>  {
        window.scroll({top: d + scroll - offset, behavior: 'smooth'});
      }, 25)
    }
  },

  mounted() {
    this.$bus.$on('category:top', this.onCategory)
  },

  beforeDestroy() {
    this.$bus.$off('category:top', this.onCategory)
  }
}
</script>
