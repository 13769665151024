<template>
  <section
    :class="classes"
    v-if="!!category">
    <div class="t-hierarchy-products__header">
      <!-- Back -->
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="nav-categories__back"
        icon-pre="arrow-left"
        @click="$emit('back')"
        v-if="isCategoryAside"
      >{{ back }}</actions-button>
    </div>

    <!-- Body -->
    <div
      ref="scroll"
      class="t-hierarchy-products__body">
      <h2 class="t-hierarchy-products__title">{{ category.name }}</h2>

      <div class="category__body">
        <ul class="category__products">
          <li
            class="category__product"
            :key="product.id"
            v-for="product in category.products"
            v-scroll-reveal>
            <product-card :product="product" />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import NavCategories from '@/modules/catalog/components/ui/nav-categories'
import ProductCard from '@/modules/catalog/components/product/card-default'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CatalogTemplateHierarchyProducts',

  components: {
    NavCategories,
    ProductCard
  },

  mixins: [ MixinMultishop, ],

  computed: {
    ...mapState({
      category: state => state['sayl-front.catalog'].category,
    }),

    back(){
      return this.$t('resto.catalog_back_all_categories')
    },

    classes(){
      return {
        't-hierarchy-products': true
      }
    },

    isCategoryAside(){
      return this.$basil.get(this.oat, 'category_navigation', 'top') === 'aside'
    },
  },
}
</script>
