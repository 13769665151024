<template>
  <div class="catalog-filters">
    <forms-input 
      :appearance="$pepper.Appearance.SUBTLE"
      :clearable="true"
      :placeholder="$t('resto.placeholder_search_product')"
      type="search"
      @clear="onSearchClear"
      @input="onFilter"
      v-model="filters.search"
    />

    <actions-button
      :appearance="$pepper.Appearance.SUBTLE"
      class="catalog-filters__cta"
      :class="{ '-is-active': hasFilters }"
      icon-pre="adjustments"
      :key="key"
      :size="$pepper.Size.S"
      :title="$t('resto.filters_title')"
      @click="onShowFilters"
      v-if="hasPossibleFilters"
      ><template v-if="hasFilters">{{ filtersLabel }}</template>
    </actions-button>

    <modal-filters 
      :visible="showModalFilters"
      @close="onCloseFilters"
    />
  </div>
</template>

<script>
import ModalFilters from './modal-filters'

export default {
  name: 'CatalogFilters',
  
  components: {
    ModalFilters
  },

  inject: ['$catalog'],

  data() {
    return { 
      debounce: null,
      key: 0,
      showModalFilters: false
    }
  },

  computed: {
    filters() {
      return this.$basil.get(this.$catalog, '_product.filters', {})
    },

    filtersCount() {
      let count = 0;
      count += this.$basil.get(this.filters, 'excludedAllergens.length', 0) > 0 ? 1 : 0;
      return count
    },

    filtersLabel() {
      return this.hasFilters && !this.showModalFilters ? 
        this.$t('resto.filters_button_label', { count: this.filtersCount }) : //`${this.filtersCount} filters` : 
        null
    },

    hasFilters(){
      return this.filtersCount > 0 
    },

    hasPossibleFilters(){
      let ret = [this.$basil.get(this.$catalog, '_product.allergens.length', 0) > 0]
      return ret.filter(r => r === true).length > 0
    }
  },
  
  methods: {
    onFilter() {
      if(!this.$basil.isNil(this.debounce)) {
        clearTimeout(this.debounce)
      }

      this.key++
      this.debounce = setTimeout(this.$catalog.filter.bind(this.$catalog, null), 250)
    },

    onCloseFilters() {
      this.key++
      this.showModalFilters = false
    },

    onShowFilters() {
      this.key++
      this.showModalFilters = true
    },

    onSearchClear() {
      this.filters.search = ''
      this.onFilter
    }
  }
}
</script>
