<template>
  <div
    v-if="hasCategories"
    class="t-spa">
    <!-- Announce -->
    <notice-banner 
      v-if="hasAnnounce"
      v-scroll-reveal>
      <template>
        <ui-wysiwyg :value="announceText" />
      </template>
    </notice-banner>

    <div class="t-spa__categories">
      <div
        v-for="category in categories"
        :key="category.id"
        class="t-spa__category category">

        <header
          :id="category.id"
          class="category__header"
          v-scroll-reveal>
          <h2 class="category__title">{{ category.name }}</h2>

          <p
            v-if="category.description"
            class="category__description"
          >{{ category.description }}</p>
        </header>

        <div class="category__body">
          <ul class="category__products">
            <li
              v-for="product in category.products"
              :key="product.id"
              class="category__product"
              v-scroll-reveal>
              <product-card
                :disabled="product.hidden"
                :product="product"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Toolbar -->
    <catalog-toolbar v-if="!isCatalogReadonly"/>
  </div>

  <div
    class="t-spa__categories"
    v-else>
    <data-empty :title="$t('resto.catalog_no_products')" />
  </div>
</template>

<script>
import {
  mapMutations,
  mapState,
} from 'vuex'

import CatalogToolbar from '@/modules/catalog/components/catalog/toolbar'
import ProductCard from '@/modules/catalog/components/product/card-default'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CatalogTemplateSpa',

  components: {
    CatalogToolbar,
    ProductCard,
  },

  inject: [
    '$catalog',
    '$challenge',
    '$embed',
  ],

  mixins: [
    MixinMultishop,
  ],

  data(){
    return{
      enabled: true,
      indexes: [],
      observer: null,
      resizeListener: null,
      scrollListener: null,
      debounce: null,
      clicked: false
    }
  },

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].controller.cart,
      categoriesS: state => state['sayl-front.catalog'].categories,
      category: state => state['sayl-front.catalog'].category,
      service: state => state['sayl-front.service'].service,
    }),

    announceText() {
      return this.$basil.get(this.$embed, 'embed.model.announceText', null)
    },

    categories() {
      return this.categoriesS || []
    },

    hasAnnounce() {
      return this.$basil.get(this.$embed, 'embed.model.announce', false) === true && this.$basil.get(this.$embed, 'embed.model.announceText', '') !== '' 
    },

    hasCategories() {
      return this.categories && this.categories.length > 0
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isLoading() {
      return this.$catalog.loading
    },


    offsetTop() {
      let searchable = this.oat.hasSearch;
      return -1 * 16 * (searchable === true ? 11 : 8)
    },
  },

  watch: {
    category: {
      handler(n, o){
        if (this.enabled){
          this.onCategory(n)
        }
      }
    },

    categories:{
      immediate: true,
      handler(n, o) {
        this.reset()
      }
    }
  },

  methods: {
    ...mapMutations({
      setCategory: 'sayl-front.catalog/setCategory'
    }),

    onChallenge() {
      this.$router.push({ name: 'sayl-front.challenges' })
    },

    onCategory(category) {
      if (!category){
        return
      }

      this.clicked = true
      const e = document.getElementById(category.id);

      if(!this.$basil.isNil(e)) {
        const y = e.getBoundingClientRect().top + window.pageYOffset + this.offsetTop;

        window.scrollTo({top: y, behavior: 'smooth'});
        this.$bus.$emit('injekt.sp.category', category.id);

        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.clicked = false
        }, 1000)
      }
    },

    onResize() {
      this.reset()
    },

    reset() {
      let children = Array.from(document.getElementsByClassName('t-spa__category'));
      this.indexes = Array(this.categories.length).fill(false);

      this.observer = new IntersectionObserver(this.updatePositions, {
        delay: 100,
        trackVisibility: true,
        rootMargin: `${this.offsetTop}px 0px 0px 0px`,
      })

      if(this.category == null) {
        this.setCategory(this.categories[0])
      }

      children.forEach( c => this.observer.observe(c) );
      this.clicked = false
    },

    updatePositions(entries) {
      let children = Array.from(document.getElementsByClassName('t-spa__category'))

      entries.forEach(e => {
        let i = -1;
        children.forEach((c, j) => {
          if (c === e.target){
            i = j
          }
        })

        if (i > -1){
          this.indexes[i] = e.isIntersecting
        }
      })

      let n = this.indexes.indexOf(true)
      if(!this.clicked) {
        this.setCategory(this.categories[n])
      }

      // if(n < 0) {
      //   this.setCategory(this.categories[0])
      // }

      // To avoid a duplicate update loop
      this.enabled = false
      setTimeout(function(){
        this.enabled = true
      }.bind(this), 100)
    },
  },

  mounted() {
    this.resizeListener = this.onResize.bind(this)
    window.addEventListener('resize', this.resizeListener)

    this.reset()
  },

  beforeDestroy() {
    this.resizeListener = null
    window.removeEventListener('resize', this.resizeListener)
  }
}
</script>
