<template>
  <nav class="view-catalog-altcat">
    <ul class="view-catalog-altcat__list">
      <li
        v-for="(c, i) in categories"
        :key="i"
        class="view-catalog-altcat__item">
        <button
          @click="$emit('category', c)"
          class="view-catalog-altcat__child">
          <div class="view-catalog-altcat__label">{{ c.name }}</div>
          <output class="view-catalog-altcat__count">{{ $t('resto.category_count', {count: c.products.length}) }}</output>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NavCategoriesAside',

  computed: {
    ...mapState({
      categories: state => state['sayl-front.catalog'].categories,
      products: state => state['sayl-front.catalog-product'].products
    }),
  }

}
</script>
