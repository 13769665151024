<template>
  <section
    :class="classes"
    v-if="hasCategories">

    <button
      class="t-hierarchy-categories__category category"
      :key="category.id"
      @click="$emit('category', category)"
      v-for="category in categories"
      v-scroll-reveal>

      <!-- Illustration -->
      <div
        v-if="showPictures"
        :class="{'category__picture': true, '-empty': !category.picture }">
        <ui-img
          :alt="category.name"
          class="category__illustration"
          :src="category.picture"
          v-if="category.picture"
        />
      </div>

      <!-- Body -->
      <div class="category__body">
        <div class="category__group">
          <div class="category__title">{{ category.name }}</div>
          <div class="category__count">{{ category.nProducts }} products</div>
        </div>
        <div
          class="category__description"
          v-if="category.description"
        >{{ category.description }}</div>
      </div>
    </button>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import UiImg from '@/components/ui/img'
import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CatalogTemplateHierarchyCategories',

  components: {
    UiImg
  },

  mixins: [
    MixinMultishop
  ],

  computed: {
    // ...mapGetters({
    //   oat: 'bootstrap/oat'
    // }),

    ...mapState({
      categories: state => state['sayl-front.catalog'].categories
    }),

    classes(){
      return {
        't-hierarchy-categories': true,
        '-no-pictures': this.showPictures === false
      }
    },

    hasCategories(){
      return this.categories && this.categories.length > 0
    },

    hasOneCategory(){
      return this.categories && this.categories.length === 1
    },

    showPictures(){
      let hasPicture = this.categories.filter( c => !!c.picture ).length > 0;
      return hasPicture && this.oat.is_category_pictures_enabled
    }
  },

  watch: {
    hasCategories() {
      if(this.hasOneCategory) {
        this.$emit('category', this.categories[0])
      }
    },
  },
}
</script>
